<template>
  <div>
    <v-btn class="mt-6" color="primary" :to="{ name: 'new_product' }"> {{ translate('New Product') }} </v-btn>

    <v-card>
      <v-card-title>
        {{ translate('Products') }}
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-simple-table fixed-header>
        <v-data-table :search="search" :headers="headers" :items="items" :items-per-page="20" class="elevation-1">
          <template v-slot:item.image="{ item }">
            <div style="
                overflow: visible;
                position: relative;
                min-width: 55px;
                max-width: 55px;
                min-height: 55px;
                max-height: 55px;
                margin: 5px;
                padding: 5px;
                border-style: dashed;
              ">
              <a :href="item.image_url" target="_blank"><v-img style="height: 45px; width: 100%"
                  :src="item.image_url"></v-img></a>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon style="color: var(--v-warning-base)" @click.stop="openEdit(item)" small> mdi-pencil </v-icon>

            <v-dialog :retain-focus="false" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click.stop="
                  editing_item = JSON.parse(JSON.stringify(item))
                item.delete_dialog = true
                  " small style="color: var(--v-error-base)" v-bind="attrs" v-on="on">
                  mdi-delete
                </v-icon>
              </template>

              <v-card>
                <v-card-title class="text-h5">deleting {{ item.name }}</v-card-title>
                <v-card-text> {{ translate('Are you sure want to delete this item') }}? </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="delete_dialog = false">{{ translate('Cancel') }}</v-btn>
                  <v-btn color="primary" @click="handleDelete(item.id)">{{ translate('OK') }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { mdiClipboardPlusOutline, mdiDotsVertical } from '@mdi/js'
import { deleteProduct, editProduct, getProducts, getMeasurements } from '@/ApiManager'

export default {
  setup() {
    const edit_product_name = ref('')
    const edit_product_buy_price = ref('')
    const edit_product_sell_price = ref('')
    const edit_product_measurement = ref('')

    return {
      icons: {
        mdiClipboardPlusOutline,
        mdiDotsVertical,
      },
      edit_product_name,
      edit_product_buy_price,
      edit_product_sell_price,
      edit_product_measurement,
    }
  },

  data() {
    return {
      search: '',
      dialog: false,
      delete_dialog: false,
      edit_product_id: 0,
      measurements: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Image', value: 'image' },

        { text: this.translate('Name'), value: 'name' },
        { text: this.translate('Buy Price'), value: 'buy_price' },
        { text: this.translate('Sell Price'), value: 'sell_price' },
        { text: this.translate('Type'), value: 'type' },
        { text: this.translate('Machine'), value: 'machine_name' },
        { text: this.translate('Category'), value: 'category_name' },

        { text: this.translate('Actions'), value: 'actions' },
      ],
      items: [],
    }
  },
  async created() {
    this.$store.state.loading = true
    getProducts().then(response => {
      this.items = response
      this.$store.state.loading = false
    })

  },
  methods: {
    openEdit(item) {
      this.$router.push({
        name: 'edit_product',
        query: {
          id: item.id,
        },
      })
    },
    async handleDelete(id) {
      this.delete_dialog = false
      await deleteProduct(id)
      getProducts().then(response => (this.items = response))
    },
    setupEdit(id) {
      //setup the default values
      var item = this.items.find(item => item.id === id)
      console.log(item)
      this.edit_product_name = item.name
      this.edit_product_id = item.id
      this.edit_product_measurement = { id: item.measurement_id, name: item.measurement_name }
      this.edit_product_buy_price = item.buy_price
      this.edit_product_sell_price = item.sell_price
    },

    async handleEdit(id) {
      this.dialog = false
      await editProduct(
        this.edit_product_id,
        this.edit_product_name,
        this.edit_product_buy_price,
        this.edit_product_sell_price,
        this.edit_product_measurement.id,
      )
      this.edit_product_name = ''
      this.edit_product_buy_price = ''
      this.edit_product_sell_price = ''
      this.edit_product_measurement = ''
      getProducts().then(response => (this.items = response))
    },
  },
}
</script>
